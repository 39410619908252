import { getOverrideValue } from '../generator/generator';
import { decodeID } from '../helpers/id-encoder-decoder';

import { FieldResolver } from './resolvers.type';

type NodeArgs = {
  id: string;
};
export const resolveNode: FieldResolver<NodeArgs> = (source, { id }, _context, info) => {
  const overrides = getOverrideValue(source, info.path.key.toString());
  if (overrides === null) {
    return null;
  }

  const [type] = decodeID(id, info);
  return { type, id, overrides };
};

type NodesArgs = {
  ids: string[];
};
export const resolveNodes: FieldResolver<NodesArgs> = (source, { ids }, _context, info) => {
  const nodesOverrides = getOverrideValue(source, info.path.key.toString());
  if (nodesOverrides === null) {
    return null;
  }

  const allOverrides = Array.isArray(nodesOverrides)
    ? nodesOverrides
    : Array.from({ length: ids.length });
  return ids.map((id, index) => {
    const overrides = allOverrides[index];
    if (overrides === null) {
      return null;
    }
    const [type] = decodeID(id, info);
    return { type, id, overrides };
  });
};
